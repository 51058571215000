import Typography from 'typography'
//import Wordpress2016 from 'typography-theme-wordpress-2016'
import Lincoln from 'typography-theme-lincoln'


Lincoln.overrideThemeStyles = () => {
  return {
    'a.gatsby-resp-image-link': {
      boxShadow: `none`,
    },
    'a': {
      backgroundImage: `none`,
      textShadow: `none`,
      color: "#ff6677"
    },
    'a:hover': {
      color: "#bbb"
    },
    'hr':{
      border: `0`,
      height: `2px`,
      backgroundImage: `linear-gradient(to right,white,black,white)`,
    },
    'body':{
      backgroundColor: "#f0f0f0"
    },
    '.p8_menu_button':{
      display:'inline !important',
    }

  }
}
/*
delete Wordpress2016.googleFonts
*/
const typography = new Typography(Lincoln)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
